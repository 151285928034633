<template>
  <div class="container">
    <div class="content-left">
      <div class="row">
        <div class="col-xs-12 col-md-5">
          <div class="info-text-caption">Lokal folder i alla kommuner</div>
          <div class="info-text">
            Husguiden distribueras årligen till cirka 150.000 husägare över hela Sverige,
            från Ystad i söder till Haparanda i norr. Vi täcker i stort sett alla kommuner i landet, stora som små.
          </div>

          <div class="info-text-caption">Stor annonsyta & valfri layout</div>
          <div class="info-text">
            Vi erbjuder dig en snyggt förpackad annons i A4-format.
            Använd ditt ordinarie annonsmaterial eller låt oss hjälpa till med layouten utan extra kostnad.
            Tillsammans har vi ett flexibelt samarbete där du kan byta innehåll efter säsong och kampanjperioder.
          </div>

          <div class="info-text-caption">Kontinuerlig uppföljning</div>
          <div class="info-text">
            Som kund loggar du in på vår hemsida där du enkelt ser namn på mottagaren,
            adress, postadress, nya bostadsadressen/fastighetsbeteckningen, kommun,
            taxeringsvärde, typ av ärende, beskrivning etc. till alla nyblivna husägare som erhållit vårt utskick.
          </div>
          <div class="info-text">
            Ditt företag presenteras i en lokal folder tillsammans med 
            ett begränsat antal andra noga utvalda företag i din kommun.
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="ad-sales-letter-container">
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/56779_Colorama_Tappers-1.jpg" />
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/98586_Svenssons-1.jpg" />
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/635679_Siljansnäs_Eltjänst_Elon_Leksand-1.jpg" />
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/2663072_HP Rör_Värme_Bad_Värme-1.jpg" />
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/3277761_Elon Ljud_Bild_Falkenberg-1.jpg" />
            <img class="pdf-preview" width="113" height="160" src="../../../assets/images/sales_letters/3553095_Optimalfönster-1.jpg" />
          </div>
        </div>

        <div class="col-xs-12">
          <p class="open-map-btn">
            <!--<a href="{{ asset('bundles/husguiden/pdf/map.pdf') }}" target="_blank" title="Öppna PDF"><strong>Öppna Kommunkarta</strong></a>-->
          </p>
        </div>
      </div>
    </div>

    <div class="advertisement-layout-container">
      <div class="advertisement-layout-info">
        <div class="advertisement-layout-material">Layout & Annonsmaterial</div>
        <div class="advertisement-layout-sizing">
          <div>
            <b>Bilder:</b> Bör ha en upplösning på 250 dpi i 100 % storlek.
            Filformat JPG, PNG, PSD eller TIF.
          </div>
          <div>
            <b>Logotyper:</b> Vektoriserad med all text i banor.
            Filformat AI, EPS, PDF eller SVG.
          </div>
          <div>
            <b>Text:</b> Utförlig text och kontaktuppgifter som ska vara med.
          </div>
          <div>
            <b>Layout:</b> Önskemål, skiss eller exempel på layout och/eller grafisk profil som vi ska följa.
          </div>
          <div>
            <b>Teckensnitt:</b> Vill ni använda ett teckensnitt som vi inte har tillgång till behöver ni förse oss med det.
          </div>
        </div>
        <div class="advertisement-layout-contact-info">
          Epost material & layout:
          <a href="mailto:material@husguiden.nu">material@husguiden.nu</a>
        </div>
        <div class="advertisement-layout-material">
          Materialspecifikation
        </div>
        <div class="advertisement-layout-section">
          <div class="downloadable-pdf">
            <img
              class="pdf-preview"
              width="120"
              :src="require('../../../assets/images/HG_specifikation_page-0001.jpg')"
            />
            <a
              href="/assets/pdf/HG_specifikation.pdf"
              download="HG_specifikation.pdf"
            >
              <button
                class="btn btn-husguiden"
              >
                Hämta som PDF (1,04 MB)
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="advertisement-participation-panel">
      <div>Vill du medverka i foldern?</div>
      <div>Vill du nå ut till fler kommuner?</div>
      <div>
        <a href="mailto:medverka@husguiden.nu">medverka@husguiden.nu</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/** Advertisement **/

.row {
  margin-right: 0;
}

/*.info-text {
  font-size: 12px;
}*/

.info-text-caption {
  color: #273548;
  font-size: 18px;
  font-weight: 700;
  color: green;
}

@media (min-width: 0px) {
  .open-map-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
  }

  .content-left {
    //    margin-top: 10px;
    margin-top: 40px;
  }

  .advertisement-layout-container {
    width: 100%;
    background: #eeedec;
  }

  .advertisement-layout-info {
    text-align: center;
  }

  .advertisement-layout-material {
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .ad-sales-letter-container {
    text-align: center;
  }

  .advertisement-participation-panel {
    margin-top: 10px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .open-map-btn {
    display: none;
  }

  .info-text-caption {
    text-align: center;
  }

  .info-text {
    margin-bottom: 10px;
    text-align: center;
  }

  /*.info-text-caption {
    text-align: left;
    font-weight:bold;
    color:green;
  }

  .info-text-caption {
    text-align: left;
  }*/

  .open-map-btn {
    display: none;
  }

  .map-img {
    display: block;
    position: absolute;
    /*background-image: url('../images/karta.png');*/
    background-repeat: no-repeat;
    width: 253px;
    height: 580px;
  }

  .advertisement-layout-container {
    width: 53%;
    background: #eeedec;
  }

  .advertisement-layout-info {
    margin-top: 20px;
    padding: 10px;
    font-size: 14px;
  }

  .advertisement-layout-material,
  .advertisement-layout-sizing,
  .advertisement-layout-contact-info {
    text-align: left;
  }

  .advertisement-layout-section {
    text-align: left;
    // padding: 8px;
  }

  .advertisement-participation-panel {
    margin-left: 5%;
    text-align: left;
    font-size: 14px;
  }

  .ad-sales-letter-container {
    display: block;
    margin-top: 320px;
    margin-left: 180px;
    position: absolute;
  }

  .ad-sales-letter-container img:nth-child(1) {
    display: block;
    z-index: 7;
    position: absolute;
    -ms-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

  .ad-sales-letter-container img:nth-child(2) {
    display: block;
    z-index: 6;
    position: absolute;
    left: 85px;
    top: -15px;
    -ms-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  .ad-sales-letter-container img:nth-child(3) {
    display: block;
    z-index: 5;
    position: absolute;
    left: 165px;
    top: -49px;
  }

  .ad-sales-letter-container img:nth-child(4) {
    display: block;
    z-index: 4;
    position: absolute;
    left: 240px;
    top: -135px;
    -ms-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  .ad-sales-letter-container img:nth-child(5) {
    display: block;
    z-index: 3;
    position: absolute;
    left: 215px;
    top: -220px;
  }

  .ad-sales-letter-container img:nth-child(6) {
    display: block;
    z-index: 2;
    position: absolute;
    left: 150px;
    top: -270px;
    -ms-transform: rotate(-2deg);
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  .ad-sales-letter-container img:nth-child(7) {
    display: block;
    z-index: 1;
    position: absolute;
    left: 60px;
    top: -300px;
    -ms-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
}

.pdf-preview {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.downloadable-pdf {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.downloadable-pdf .btn {
  margin: 8px;
}

@media (min-width: 1200px) {
  .advertisement-layout-material {
    text-transform: uppercase;
    font-weight: bold;
  }

  .ad-sales-letter-container {
    margin-left: 220px;
  }
}
</style>
