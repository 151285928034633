var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",staticStyle:{"max-width":"1640px"}},[_c('div',{staticClass:"title-bar"},[_c('h3',{staticClass:"back-button"},[_c('a',{staticClass:"buttonlike",staticStyle:{"color":"black"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"cui-arrow-left d-inline button-sibling-right"}),_c('div',{staticClass:"d-inline"},[_vm._v("Tillbaka")])])]),_c('h3',{staticClass:"page-title"},[(_vm.$route.params.pageTitle)?_c('div',{staticClass:"d-inline"},[_vm._v("Utskick - "+_vm._s(_vm.$route.params.pageTitle))]):_vm._e()]),_c('button',{staticClass:"btn btn-husguiden",attrs:{"id":"download"},on:{"click":_vm.download}},[_c('font-awesome-icon',{attrs:{"icon":"file-download"}}),_vm._v("Ladda ner ")],1)]),_c('div',[(!_vm.isLoading)?_c('expandable-table',{attrs:{"columnNames":[
        _vm.$t('Type'),
        _vm.$t('Name'),
        _vm.$t('Address'),
        _vm.$t('Post address'),
        _vm.$t('Phone'),
        _vm.$t('Location'),
        _vm.$t('Municiple'),
        _vm.$t('Value'),
        _vm.$t('Description') ],"sortingFunctions":[_vm.typeSort, _vm.nameSort, _vm.addressSort, _vm.postCodeSort, _vm.phoneNumberSort, _vm.locationSort, _vm.municipleSort, _vm.valueSort, _vm.descriptionSort],"values":this.detailList,"getKey":function (value) { return value.cooId; },"tableItemComponent":_vm.TableItem,"expansionComponent":_vm.TableItemExpansion,"tableItemComponentProps":{onRequestOpenDialog: _vm.handleRequestOpenDialog},"expansionComponentProps":{onRequestOpenDialog: _vm.handleRequestOpenDialog}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }