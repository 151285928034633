var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container fill-height"},[_c('div',{staticClass:"separate-children-container fill-height"},[_c('div',{staticClass:"head-container control-section"},[_c('div',{staticClass:"head-container-part"},[_c('search',{attrs:{"onRequestSearch":_vm.handleRequestSearchPersons,"placeholder":_vm.$t('Search for persons in the deliveries')}})],1)]),(_vm.viewMode === 'browse')?_c('expandable-table',{attrs:{"columnNames":[
        _vm.$t('Delivery date'),
        _vm.$t('Number of delivery') ],"sortingFunctions":[_vm.dateSort, _vm.countSort],"values":this.dispatches,"getKey":function (value) { return value.id; },"tableItemComponent":_vm.BrowseListItem,"tableItemComponentProps":{editionId: _vm.profileConfigs[_vm.activeProfileConfigId] && _vm.profileConfigs[_vm.activeProfileConfigId].editionId}}}):_vm._e(),(_vm.viewMode === 'browse')?_c('div',{staticClass:"control-section"},[_c('paginator',{attrs:{"itemCount":this.totalDispatchCount,"itemsPerPage":_vm.pageSize,"pageIndex":_vm.pageIndex,"onRequestChangePage":_vm.handleRequestChangePage,"onRequestChangePageSize":_vm.handleRequestChangePageSize,"label":_vm.$t('Deliveries per page:')}})],1):_vm._e()],1),_c('div',[(_vm.viewMode == 'search')?_c('a',{staticClass:"nav-link buttonlike",staticStyle:{"color":"black"},on:{"click":function($event){_vm.viewMode = 'browse'}}},[_c('i',{staticClass:"cui-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t('Go back'))+" ")]):_vm._e(),(_vm.viewMode == 'search' && _vm.searchResults.length > 0)?_c('expandable-table',{attrs:{"columnNames":[
        _vm.$t('Name'),
        _vm.$t('Municiple'),
        _vm.$t('Description'),
        _vm.$t('Delivery date'),
        _vm.$t('Planned investment') ],"sortingFunctions":[_vm.nameSort, _vm.municipleSort, _vm.descriptionSort, _vm.deliveryDateSort],"values":_vm.searchResults,"getKey":function (value) { return value.cooId; },"tableItemComponent":_vm.SearchResultItem,"expansionComponent":_vm.SearchResultItemExpansion,"tableItemComponentProps":{onRequestOpenDialog: _vm.handleRequestOpenDialog},"expansionComponentProps":{onRequestOpenDialog: _vm.handleRequestOpenDialog}}}):_vm._e(),(_vm.viewMode == 'search' && _vm.searchResults.length < 1 && !_vm.isLoading)?_c('div',{staticClass:"status-message"},[_vm._v(_vm._s(_vm.$t('No results')))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }